import React, { useRef, useState } from 'react'; 
import './Modal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // 追加
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Modal = ({ show, onClose, children, onBrainstorming, source, contextText, setContextText, questionText, setQuestionText }) => {
  const contentRef = useRef(null);
  const [responses, setResponses] = useState([]);

  if (!show) {
    return null;
  }

  const handleCopy = () => {
    const textToCopy = contentRef.current.innerText;
    navigator.clipboard.writeText(textToCopy).then(() => {
      // alert('内容がクリップボードにコピーされました');
    }).catch(err => {
      console.error('コピーに失敗しました:', err);
    });
  };

  const handleBrainstorming = async () => {
    const text = contentRef.current.innerText;
    const response = await onBrainstorming(text);
    setResponses([response, ...responses]);
  };
  
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <button className="modal-close" onClick={onClose} style={{ float: 'right'}}>Close</button>
          {source !== 'idea' && <button className="modal-copy" onClick={handleCopy} style={{ float: 'right', marginLeft: '5px' , padding:'2px'}}>Copy</button>}
        </div>
        <div ref={contentRef}>
          {source === 'idea' ? (
            <div style={{ textAlign: 'left' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                <label>Context</label>
                <button
                  style={{ marginLeft: '1px' }}
                  onClick={() => {
                    const textarea = document.createElement('textarea');
                    textarea.value = contextText;
                    document.body.appendChild(textarea);
                    textarea.select();
                    document.execCommand('copy');
                    document.body.removeChild(textarea);
                  }}
                >
                  Copy
                </button>
              </div>
              <textarea
                value={contextText}
                onChange={(e) => setContextText(e.target.value)}
                style={{ width: '100%', height: '100px', marginBottom: '10px' }}
              />
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                <label>You</label>
                <button
                  style={{ marginLeft: '1px' }}
                  onClick={() => {
                    const textarea = document.createElement('textarea');
                    textarea.value = questionText;
                    document.body.appendChild(textarea);
                    textarea.select();
                    document.execCommand('copy');
                    document.body.removeChild(textarea);
                  }}
                >
                  Copy
                </button>
                {source !== 'summary' && <button className="modal-brainstorming" onClick={handleBrainstorming} style={{ float: 'right', marginLeft: '5px' }}>Thought</button>}
              </div>
              <textarea
                value={questionText}
                onChange={(e) => setQuestionText(e.target.value)}
                style={{ width: '100%', height: '100px', marginBottom: '10px' }}
              />
            </div>
          ) : (
            children
          )}
        </div>
        {responses.map((response, index) => (
          <div key={index} style={{ marginTop: '10px' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              <label>Partner</label>
              <button
                style={{ marginLeft: '1px' }}
                onClick={() => {
                  const textarea = document.createElement('textarea');
                  textarea.value = response;
                  document.body.appendChild(textarea);
                  textarea.select();
                  document.execCommand('copy');
                  document.body.removeChild(textarea);
                }}
              >
                Copy
              </button>
            </div>
            <textarea
              value={response}
              readOnly
              style={{ width: '100%', height: '500px' }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Modal;