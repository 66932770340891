// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 3000; /* 他のコンテンツの上に表示されるようにする */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* オプション: 視認性を高めるための影を追加 */
}
  
  .logo {
    /* ロゴのスタイル */
  }
  
  .container {
    margin-top: 60px; /* ヘッダーの高さに応じてこの値を調整 */
  }
  
  .file-list {
    /* ファイルリストのスタイル */
    text-align: left; /* 左寄せ */
  }
  
  .file-item {
    margin-left: 20px; /* デフォルトのインデント */
  }
  
  .icon {
    /* アイコンのスタイル */
  }

  .scroll-buttons {
    position: fixed;
    right: 20px;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .scroll-to-top, .scroll-to-bottom {
    background-color: #fff;
    border: none;
    padding: 10px;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  
  .scroll-to-top:hover, .scroll-to-bottom:hover {
    background-color: #f0f0f0;
  }

  .header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    z-index: 3000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }`, "",{"version":3,"sources":["webpack://./src/ListView.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,WAAW;EACX,uBAAuB;EACvB,aAAa,EAAE,yBAAyB;EACxC,wCAAwC,EAAE,0BAA0B;AACtE;;EAEE;IACE,YAAY;EACd;;EAEA;IACE,gBAAgB,EAAE,sBAAsB;EAC1C;;EAEA;IACE,iBAAiB;IACjB,gBAAgB,EAAE,QAAQ;EAC5B;;EAEA;IACE,iBAAiB,EAAE,gBAAgB;EACrC;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;;EAEA;IACE,sBAAsB;IACtB,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,eAAe;IACf,MAAM;IACN,WAAW;IACX,uBAAuB;IACvB,aAAa;IACb,wCAAwC;EAC1C","sourcesContent":[".header {\n  position: fixed;\n  top: 0;\n  width: 100%;\n  background-color: white;\n  z-index: 3000; /* 他のコンテンツの上に表示されるようにする */\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* オプション: 視認性を高めるための影を追加 */\n}\n  \n  .logo {\n    /* ロゴのスタイル */\n  }\n  \n  .container {\n    margin-top: 60px; /* ヘッダーの高さに応じてこの値を調整 */\n  }\n  \n  .file-list {\n    /* ファイルリストのスタイル */\n    text-align: left; /* 左寄せ */\n  }\n  \n  .file-item {\n    margin-left: 20px; /* デフォルトのインデント */\n  }\n  \n  .icon {\n    /* アイコンのスタイル */\n  }\n\n  .scroll-buttons {\n    position: fixed;\n    right: 20px;\n    bottom: 20px;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n  }\n  \n  .scroll-to-top, .scroll-to-bottom {\n    background-color: #fff;\n    border: none;\n    padding: 10px;\n    border-radius: 50%;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);\n    cursor: pointer;\n  }\n  \n  .scroll-to-top:hover, .scroll-to-bottom:hover {\n    background-color: #f0f0f0;\n  }\n\n  .header {\n    position: fixed;\n    top: 0;\n    width: 100%;\n    background-color: white;\n    z-index: 3000;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
